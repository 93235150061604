PathUtils = require '../../../utils/PathUtils/PathUtils.coffee'

createTwoLevelMap = require '../../twoLevelMap/twoLevelMap.coffee'

module.exports = (cachedData, onLoadSuccessful) ->

  localeCache = createTwoLevelMap cachedData
  pendingRequestsCallbacks = createTwoLevelMap()

  return (locale, namespace, options, callback) ->
    if KV.locales
      return callback null, KV.locales[namespace]

    cachedLocale = localeCache.get locale, namespace
    return callback null, cachedLocale if cachedLocale

    pendingCallbacks = pendingRequestsCallbacks.get locale, namespace
    if pendingCallbacks
      pendingCallbacks.push callback
    else
      # create the callback queue
      pendingRequestsCallbacks.set locale, namespace, [callback]

      emitToCallbacks = (err, data) ->
        _.each pendingRequestsCallbacks.get(locale, namespace), (cb) ->
          cb err, data
        pendingRequestsCallbacks.reset locale, namespace

      $.ajax
        async: false
        url: PathUtils.getClientRootPath() + "locales/#{locale}/#{namespace}.json"
        dataType: 'json'
        success: (data) ->
          localeCache.set locale, namespace, data
          emitToCallbacks null, data
          onLoadSuccessful?()
        error: (err) ->
          localeCache.set locale, namespace, {}
          emitToCallbacks null, {}

module.exports.setUrlPrefix = (prefix) ->
  urlPrefix = prefix

module.exports = (map = {}) ->
  get: (keyOne, keyTwo) ->
    map[keyOne]?[keyTwo]

  set: (keyOne, keyTwo, data) ->
    map[keyOne] ||= {}
    map[keyOne][keyTwo] = data

  reset: (keyOne, keyTwo) ->
    map[keyOne] ||= {}
    map[keyOne][keyTwo] = null

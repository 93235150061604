module.exports = EventsMixin =

  # Only used by this mixin, should not be modified anywhere else
  __nextEventId: 1

  ###*
  Bind a callback to an event. If the scope is given, the callback will
  be executed with that scope.

  @param {String} ev The name of the event to bind to.
  @param {Function} callback This callback will be run whenever the event
  is triggered.
  @param {Object=} scope The scope to run the callback in (optional).
  ###
  bind: (ev, callback, scope) ->

    # Give the callback an id, so we can find it later when unbinding
    callback.id = callback.id or EventsMixin.__nextEventId += 1

    # Bind the callback to the given scope, preserving the id
    # so it can be unbound
    if scope
      id = callback.id
      callback = if KV?.bind? then KV.bind(callback, scope) else _.bind callback, scope
      callback.id = id

    calls = @_callbacks or (@_callbacks = {})
    list = @_callbacks[ev] or (@_callbacks[ev] = [])
    list.push callback
    return


  ###*
  Remove a callback from an event.
  If no callback is given, then all callbacks will be removed from the
  event.
  If no event is given, then all callbacks will be removed from all events
  on this object.

  @param {String=} ev The name of the event to unbind from (optional).
  @param {Function=} callback The callback to unbind (optional).
  ###
  unbind: (ev, callback) ->
    calls = @_callbacks
    unless ev
      @_callbacks = {}
    else if calls
      unless callback
        calls[ev] = []
      else
        list = calls[ev]
        return  unless list
        i = 0
        l = list.length

        while i < l
          if callback.id is list[i].id
            list.splice i, 1
            break
          i += 1
    return


  ###*
  Trigger all the callbacks for the given event. All subsequent arguments
  will be passed on to the callbacks.

  If any of the callbacks return a result, this will be returned from this
  function. If multiple callbacks return a result, only the last result
  will be returned.

  @param {String} ev The name of the event to trigger.
  ###
  trigger: (ev, args...) ->
    return if @_eventsSuspended

    calls = @_callbacks
    return if not calls

    list = calls[ev]
    return if not list

    result = undefined
    for callback in list when callback?
      returned = @_callWithoutApply callback, args
      result = returned if typeof returned isnt "undefined"

    return result

  ###*
  Stop all events from being triggered.
  Calling trigger() while events are suspended will have no effect, and
  it will return undefined.
  ###
  suspendEvents: ->
    @_eventsSuspended = true
    return


  ###*
  Allow events to be triggered again.
  ###
  resumeEvents: ->
    @_eventsSuspended = false
    return


  ###*
  Proxy all the given events from the given object through this object.

  @param {Object} obj The Object to proxy the events from.
  @param {Array.<String>} events An array of event names to proxy.
  ###
  proxyAll: (obj, events) ->
    i = 0

    while i < events.length
      @proxy obj, events[i]
      i += 1
    return


  ###*
  Proxy the given event from the given object through this object.

  @param {Object} obj The Object to proxy the event from.
  @param {String} ev The name of the event to proxy.
  ###
  proxy: (obj, ev) ->
    @_proxyCallbacks = @_proxyCallbacks or {}
    @_proxyCallbacks[obj] = @_proxyCallbacks[obj] or {}
    callback = ->
      args = Array::slice.call(arguments)
      args.unshift ev
      @trigger.apply this, args

    @_proxyCallbacks[obj][ev] = callback
    obj.bind ev, callback, this
    return


  ###*
  Stop proxying a given event from given object through this object.

  @param {Object} obj The Object to stop proxying the event from.
  @param {String} ev The name of the event to stop proxying.
  ###
  unproxy: (obj, ev) ->
    return  if not @_proxyCallbacks or not @_proxyCallbacks[obj] or not @_proxyCallbacks[obj][ev] or not @_proxyCallbacks[obj][ev].unbind
    callback = @_proxyCallbacks[obj][ev]
    obj.unbind ev, callback
    return

  ###
  Call a function with a variable number of parameters without using apply.
  This is required in IE8 when calling a callback across frames.
  ###
  _callWithoutApply: (func, args) ->
    switch args.length
      when 0 then func()
      when 1 then func(args[0])
      when 2 then func(args[0], args[1])
      when 3 then func(args[0], args[1], args[2])
      when 4 then func(args[0], args[1], args[2], args[3])
      when 5 then func(args[0], args[1], args[2], args[3], args[4])
      when 6 then func(args[0], args[1], args[2], args[3], args[4], args[5])
      when 7 then func(args[0], args[1], args[2], args[3], args[4], args[5], args[6])
      when 8 then func(args[0], args[1], args[2], args[3], args[4], args[5], args[6], args[7])
      else
        throw new Error "Events currently only support a maximum of 8 arguments (due to an IE8 workaround)."

window.$ = window.jQuery = require "jquery"

require './components/common/MomentWrapper/MomentWrapper'
guruLocalisation = require './components/common/services/localization/guruLocalisation'
guruLocalisation.localise KV.initialNamespaces || ['additional']

ApiGateway = require './components/api/ClientApi/ApiGateway.coffee'
window.api = apiGateway = new ApiGateway()

apiGateway.type = 'login'

$ =>
  return unless window.location.hash

  loginForm = document.getElementById 'loginForm'
  redirectPattern = /([?|&]redirectTo\=)(.+?)(?:&|$)/i
  matches = redirectPattern.exec loginForm.action

  return if !matches

  formRedirectTo = decodeURIComponent matches[2]

  return if formRedirectTo.indexOf('#') > -1

  # pass the URL fragment to the login form's action URL if not present
  loginForm.action = loginForm.action.replace(redirectPattern, '$1' + encodeURIComponent(formRedirectTo + window.location.hash))

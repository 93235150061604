# Compilation of util functions dealing with server & client paths
module.exports = class PathUtils

  @_serverPath = null
  @_rootPath = null
  @_applicationPath = null
  @_apiPath = null

  # Sets the path on which the server application is hosted.
  @setServerPath = (path) ->
    path = path.replaceAll ' ', '%20'

    if path.charAt(0) isnt '/'
      path = '/' + path

    if path[path.length-1] isnt '/'
      path = path + '/'

    @_serverPath = path

  # Returns the root path on which the application server is hosted.
  @getServerRootPath = ->
    if @_rootPath is null
      @_rootPath = @getSchemeAndAuthority() + KV.serverPath + ( if KV.serverPath && KV.serverPath[KV.serverPath.length-1] is '/' then '' else '/')

    @_rootPath

  # Returns the root path on which this application client is hosted. This is the server root path plus the client path.
  @getClientRootPath = ->
    if @_applicationPath is null
      @_applicationPath = @getServerRootPath() + (if KV.clientPath.charAt(0) is '/' then KV.clientPath.substring 1 else KV.clientPath) + '/'

    @_applicationPath

  @getStaticContentCdnBase = ->
    return KV.staticContentCdnBase + '/'

  # Returns the path to the server API
  @getApiPath = (version = '2.2') ->
    @getServerRootPath() + 'api/' + version + '/rest'

  # Formats a relative address if required. There are two types of relative address - relative to domain and relative to
  # client. The convention is that if the address starts with a double slash it is relative to the domain and if it starts
  # with a single slash then it is relative to the client.
  @formatAddress = (address) ->
    if not address
      return ''

    if address.charAt(0) is '/'
      return @getSchemeAndAuthority() + address

    return address

  # Returns the scheme (e.g. http://) and authority (e.g. mydomain.com:8080) from which this application is delivered.
  @getSchemeAndAuthority = ->
    window.location.protocol + '//' + @getHostAndPort()

  # Returns the path on which the current application resource is deleivered, but not the portion of
  # the url that includes the resource name (i.e. the text after the last /).
  @getResourcePath = ->
    pathname = unescape window.location.pathname
    lastPathCharIndex = pathname.lastIndexOf '/'

    if lastPathCharIndex is 0
      return @getSchemeAndAuthority() + '/'

    @getSchemeAndAuthority() + pathname.substring 0, lastPathCharIndex + 1


  # Returns the portion of the current application URL needed to identify the current resource, but
  # not the query string or fragment parts of the url.
  @getPathName = ->
    unescape window.location.pathname

  # Returns the host from which this application is deleivered.
  @getHost = ->
    unescape window.location.hostname

  # Returns the port from which this application is deleivered, if explicitly used, or null
  # otherwise.
  @getPort = ->
    port = window.location.port
    if port isnt '' then unescape(port) else null


  # Returns the host from which this application is deleivered and any port if it is used.
  @getHostAndPort = ->
    host = @getHost()
    port = @getPort()

    if port isnt null then host + ':' + port else host

  # Add http:// to external link if needed
  @addHTTPToLinkAddress = (linkUrl) ->
    if linkUrl and (not ( linkUrl.matches '^[a-zA-Z]*:' or linkUrl.startsWith '/' )) then 'http://' + linkUrl else linkUrl

  # Return the value of a search parameter passes
  @getUrlSearchParameterSimpleValue = (name) ->
    parts = "#{window.location.search}".match(new RegExp("#{name}=([^&]*)"))

    decodeURIComponent(parts[1]) if parts

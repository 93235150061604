window.moment = require 'moment'
dateTimeFormat = require '../data/DateTimeFormat.coffee'

# takes the date returned by the API and returns it as a localised Moment instance
moment.parseFromApi = (date) ->
  moment(date, dateTimeFormat.RFC2822, 'en').local().locale(false)

# formats the date to a format accepted by the server
moment.fn.formatToApi = ->
  this.clone().utc().locale('en').format dateTimeFormat.RFC2822

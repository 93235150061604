bus = require './bus'
loader = require './loader'

_data = []
_updateOptions = null
_options =
  # lng: 'en'
  fallbackLng: 'en'
  useDataAttrOptions:true
  ns:
    namespaces: ['common']
    defaultNs: 'common'
  defaultValueFromContent: false
  customLoad: loader _data, ->
    bus.emit 'loadSuccessful'

module.exports =

  setInitialOptions: (locale, namespaces, updateOptions) ->
    _updateOptions = updateOptions
    _options.lng = locale if locale
    _options.ns.namespaces = _.union _options.ns.namespaces, namespaces

    _updateOptions _options

  addNamespace: (namespace) ->
    _options.ns.namespaces.push namespace
    _updateOptions _options

  setLocale: (lng) ->
    throw new Error("Can't set locale to null/undefined") unless lng
    _options.lng = lng
    _updateOptions _options
    moment.locale(lng)

  getLocale: ->
    return _options.lng

  baseOptions: _options

$ = window.$ = window.jQuery = require 'jquery'
i18n = require 'i18next-client-old'
configurationHelper = require './configurationHelper'

toLocalizationKeys = (namespace, keys) ->
  return _.map keys, (key) -> "common:#{namespace}.#{key}"

monthKeys = toLocalizationKeys 'date', [
  'january'
  'february'
  'march'
  'april'
  'may'
  'june'
  'july'
  'august'
  'september'
  'october'
  'november'
  'december'
]

shortMonthKeys = toLocalizationKeys 'date', [
  'short.january'
  'short.february'
  'short.march'
  'short.april'
  'short.may'
  'short.june'
  'short.july'
  'short.august'
  'short.september'
  'short.october'
  'short.november'
  'short.december'
]

weekdayKeys = toLocalizationKeys 'date', [
  'sunday'
  'monday'
  'tuesday'
  'wednesday'
  'thursday'
  'friday'
  'saturday'
]

shortWeekdayKeys = toLocalizationKeys 'date', [
  'short.sunday'
  'short.monday'
  'short.tuesday'
  'short.wednesday'
  'short.thursday'
  'short.friday'
  'short.saturday'
]

minWeekdayKeys = toLocalizationKeys 'date', [
  'min.sunday'
  'min.monday'
  'min.tuesday'
  'min.wednesday'
  'min.thursday'
  'min.friday'
  'min.saturday'
]

module.exports =
  toLocalizationKeys: toLocalizationKeys
  generateDatepickerLocaleObject: (localizeFn) ->

    # This is instead of:
    # _.map keys, localizeFn
    # to make this portion work in IE8
    keysToLocalization = (keys) ->
      _.map keys, (model) ->
        localizeFn model

    {
      days: keysToLocalization weekdayKeys
      daysShort: keysToLocalization shortWeekdayKeys
      daysMin: keysToLocalization minWeekdayKeys
      months: keysToLocalization monthKeys
      monthsShort: keysToLocalization shortMonthKeys
      today: localizeFn "common:bsDatepicker.Today"
      clear: localizeFn "common:Clear"
    }

  localise: (namespaces, callback) ->
    configurationHelper.setInitialOptions null, namespaces, (options) ->
      i18n.init options, ->
        moment.locale(i18n.lng())
        setTimeout ->
          $('html').i18n()
          callback && callback(i18n)
        , 0

  localiseSync: (namespaces, callback) ->
    configurationHelper.setInitialOptions null, namespaces, (options) ->
      i18n.init options, ->
        moment.locale(i18n.lng())
        $('html').i18n()
        callback && callback(i18n)

EventsMixin = require '../../generic/EventsMixin/EventsMixin.coffee'

module.exports = class ApiGateway
  _.assignIn @::, EventsMixin

  constructor: ->

  command: (command, args, callback) ->
    @trigger "command:#{command}", args, callback ? ->

  get: (property, callback) ->
    @trigger "get:#{property}", callback ? ->

  set: (property, value, callback) ->
    @trigger "set:#{property}", value, callback ? ->
